import { useState } from "react";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useFileUploadDialog } from "../../toolympus/components/files";
import { Instrument, InstrumentApiPath, useInstrumentSchema } from "./useInstrumentsList"
import { apiFetch } from "../../toolympus/api/core";
import { useDocumentModeration } from "../Administration/Moderation";

export const useInstrumentEdit = (id: number) => {
  const data = useCrudItem<Instrument>(`${InstrumentApiPath}/${id}`, {
    defaultValue: {
      _id: -1,
      title: "",
    }
  });

  const schema = useInstrumentSchema();

  const contentFile = useFileUploadDialog({
    apiPath: `${InstrumentApiPath}/${id}/content-file`,
    onUpload: () => {
      data.reload();
    }
  });

  const moderationFeed = useDocumentModeration(`instrument_${id}`);

  const [isModerationSaving, setIsModerationSaving] = useState<boolean>(false);
    const updateDocumentModeration = async (doc: any, newStatus: string) => {
      let d = data.data;
      if(data.hasChanges) {
        d = await data.save();
      }
  
      try {
        setIsModerationSaving(true);
        const updated = await apiFetch<Document>(`${InstrumentApiPath}/${id}/moderation`, "put", { moderation_status: newStatus });
        data.setData({ ...d, ...updated });
        setIsModerationSaving(false);
        moderationFeed.reload();
        return updated;
      } catch(e) {
        setIsModerationSaving(false);
        throw e;
      }
    }

  return {
    ...data,
    isLoading: data.isLoading || isModerationSaving,
    schema,
    contentFile,
    
    moderationFeed,
    updateDocumentModeration,
  };
}
