import React from "react";
import { PowerEditorBaseProps, PowerEditorBase, EditorPlugin } from "../../toolympus/components/PowerDoc";
import { ColumnsPlugin } from "../../toolympus/components/PowerDoc/plugins/Columns";
import { CustomFieldsBlockPlugin } from "../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock";
import { ExpanderPlugin } from "../../toolympus/components/PowerDoc/plugins/Expander";
import { useMediaFilesPlugin } from "../../toolympus/components/PowerDoc/plugins/MediaFiles";
import { PanelPlugin } from "../../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin";
import { ScriptPlugin } from "../../toolympus/components/PowerDoc/plugins/Script";
import { TablesPlugin } from "../../toolympus/components/PowerDoc/plugins/Tables";
import { TilesPlugin } from "../../toolympus/components/PowerDoc/plugins/Tiles";
import { createWrappedPowerEditor, PowerEditorSettingsProvider } from "../../toolympus/components/PowerDoc/PowerEditorSettingsContext";
import { SettingsBlockPlugin } from "../../toolympus/components/PowerDoc/plugins/SettingsBlock";
import { useInsertCaseRecordLinkPlugin } from "./Editor/InsertCaseRecordLinkPlugin";
import { BlockElement } from "./Editor/BlockElement";
import { useBlockImagesPlugin } from "../../toolympus/components/PowerDoc/plugins/InlineImages/BlockImagesPlugin";
import { useTextClassPlugin } from "../../toolympus/components/PowerDoc/plugins/BasicCommands/useTextClassPlugin";

export const BiodevEditorUnwrapped = (props: PowerEditorBaseProps & { extraPlugins?: EditorPlugin[] }) => {
  const caseRecordsLinksPlugin = useInsertCaseRecordLinkPlugin();
  const blockImagesPlugin = useBlockImagesPlugin();

  return (
      <PowerEditorBase
          {...props}
          customRenderElement={BlockElement}
          plugins={[
              blockImagesPlugin,
              SettingsBlockPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ExpanderPlugin,
              TablesPlugin,
              caseRecordsLinksPlugin,
              ...(props.extraPlugins || []),
          ]}
          />
  );
}

// export const BiodevEditor = createWrappedPowerEditor({
//   EditorComponent: BiodevEditorUnwrapped
// });

export const BiodevEditor = (props: PowerEditorBaseProps & { extraPlugins?: EditorPlugin[] }) => {
  return (
    <PowerEditorSettingsProvider EditorComponent={BiodevEditorUnwrapped} >
      <BiodevEditorUnwrapped {...props} />
    </PowerEditorSettingsProvider>)
}


export const BiodevCmsEditorUnwrapped = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();
  const textClassPlugin = useTextClassPlugin();
  const blockImagesPlugin = useBlockImagesPlugin();

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              mediaFilesPlugin,
              blockImagesPlugin,
              CustomFieldsBlockPlugin,
              SettingsBlockPlugin,
              TilesPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ScriptPlugin,
              ExpanderPlugin,
              TablesPlugin,
              textClassPlugin,
          ]}
          />
  );
}

export const BiodevCmsEditor = createWrappedPowerEditor({
  EditorComponent: BiodevCmsEditorUnwrapped
});
