import React from 'react';
import { useParams } from 'react-router-dom';
import { useInstrumentEdit } from './useInstrumentEdit';
import { DeleteButton, Form, FormGrid, MoreActionsMenuButton, OccupyFreeSpace, SaveButton, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { Divider, IconButton, Typography } from '@mui/material';
import { DeleteOutlined, SearchOutlined } from '@mui/icons-material';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { BiodevEditor } from '../Administration/BiodevEditor';
import { AttachedDocumentsBound } from '../Common/AttachedDocuments';
import { InstrumentApiPath } from './useInstrumentsList';
import { InboundLinksContextProvider } from '../Administration/Editor/InboundLinksContext';
import { useScrollToContentSubIdFromQuery } from '../Common/Links/useScrollToContentSubIdFromQuery';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';
import { DocumentLinksPane } from '../Common/DocumentLinks';
import { FileUploadDialog } from '../../toolympus/components/files';
import { DocumentStatus } from '../Administration/Moderation';
import { createModerationCommentPlugin } from '../Administration/Moderation/ModerationCommentPlugin';
import { PowerEditorExternalControlProviderBase } from '../../toolympus/components/PowerDoc/slate/PowerEditorControlContext';
import { ContentWithModerationWrapper, DocumentModerationPane } from '../Administration/Moderation/DocumentModerationPane';
import { StrippedIconButton } from '../../toolympus/components/primitives/StrippedButtons';
import { useDictionaries } from '../../toolympus/hooks/useDictionaries';
import { MultiSelectorDialog, useMultiSelectDialog } from '../../toolympus/components/primitives/MultiSelectorDialog';

export const InstrumentForm = () => {
  const { id } = useParams<{ id: string }>();
  const data = useInstrumentEdit(+id);

  const { controls } = useItemWithControls(data, { schema: data.schema });

  useSaveable(data);

  useScrollToContentSubIdFromQuery();

  const tabs = useTabsState([
    ["attributes","Attributes"],
    ["content","Content"],
    ["files","Files"],
  ], "attributes", "tab");

  const startCommentCommandPlugin = createModerationCommentPlugin(data.moderationFeed);

  const countriesRecords = useDictionaries()["Countries"]?.records;
  const countriesDialog = useMultiSelectDialog({
    options: countriesRecords || [],
    update: v => data.update({ countries: v }),
  });

  return (
    <Form
      title="Instrument"
      headerItems={<>
        <OccupyFreeSpace />
        {data.hasChanges && <SaveButton action={() => data.save()} />}

        <MoreActionsMenuButton
          strippedButton
          actions={[
            ["content-upload", "Import PDF", () => data.contentFile.dialog.open(), { disabled: data.hasChanges }],
          ]}
          />

        <DeleteButton
          remove={() => data.remove()}
          title="Delete the instrument?"
          button={<IconButton size="small"><DeleteOutlined /></IconButton>}
          />
        <PluggableDocumentationButton documentationKey="instruments" />
      </>}
      >
      <FormGrid columns="3fr 1fr" style={{ alignItems: "flex-end" }}>
        {controls([
          ["title"],
        ])}

        <DocumentStatus
          document={data.data}
          schema={data.schema}
          updateStatus={data.updateDocumentModeration}
          />
      </FormGrid>

      <TabsHeader state={tabs} />

      <TabPanel state={tabs.forTab("attributes")}>
        <FormGrid>
          {controls([
            ["date_adoption"],
            ["date_effective"],
            ["created_at", { disabled: true, utcToLocal: true }],
            ["source_url"],
            ["depositary_url"],
            ["created_by", { disabled: true }],
            ["depositary", { autoComplete: true }],
            ["owner_id", { autoComplete: true }],
            ["moderator_id", { autoComplete: true }],
          ])}
        </FormGrid>

        <FormGrid columns="1fr 1fr" forceEvenColumns>
          <FormGrid columns="1fr max-content" alignItems="end" noMargin gap="dense">
            {controls([
              ["countries", { autoComplete: true, replaceWithCountAfter: 4 }],
            ])}
            <StrippedIconButton onClick={() => countriesDialog.startEditing(data.data.countries || [])}><SearchOutlined /></StrippedIconButton>
          </FormGrid>

          {controls([
            ["topics", { autoComplete: true, multiSelectValueDisplay: "text" }],
          ])}
        </FormGrid>

        <FormGrid columns="1fr">

          <Typography variant="caption" component="p" color="textSecondary" style={{ marginTop: "1rem" }}>Overview</Typography>
          <BiodevEditor
            key={`${data.data._id}`}
            content={data.data.description}
            update={v => data.update({ description: v })}
            />

          <Divider />

          <Typography variant="caption" color="textSecondary">
            Associated documents
          </Typography>

          <DocumentLinksPane
            thisRecordType="instrument"
            thisRecordId={data.data._id}
            />

        </FormGrid>
      </TabPanel>

      <TabPanel state={tabs.forTab("content")}>
        <PowerEditorExternalControlProviderBase>
          <ContentWithModerationWrapper isFeedVisible={data.moderationFeed.isFeedVisible || false}>

            <InboundLinksContextProvider this_record_id={data.data._id >= 0 ? data.data._id.toString() : ""} this_record_type="instrument">
              <BiodevEditor
                key={`${data.data._id}_${data.loadBumper || ""}`}
                content={data.data.content}
                update={v => data.update({ content: v })}
                editorElementId="doc-editor"
                extraPlugins={[startCommentCommandPlugin]}
                />
            </InboundLinksContextProvider>

            <DocumentModerationPane
              data={data.moderationFeed}
              isFeedVisible={data.moderationFeed.isFeedVisible || false}
              setIsFeedVisible={data.moderationFeed.setIsFeedVisible}
              loadBumper={(data.loadBumper || 0) + (data.moderationFeed.tasks.loadBumper || 0)}
              />

          </ContentWithModerationWrapper>
        </PowerEditorExternalControlProviderBase>
      </TabPanel>

      <TabPanel state={tabs.forTab("files")}>
        <InstumentDocuments instrumentId={data.data._id} />
      </TabPanel>

      
      <FileUploadDialog
        data={data.contentFile}
        title="Import content">
        <Typography>
          Upload a PDF-file using the field below to import document content from it.
        </Typography>
        <Typography>
          The uploaded file will be added to the Files tab.
        </Typography>
        <Typography>
          Warning: if the document already has content, it will be overwritten.
        </Typography>
      </FileUploadDialog>


      <MultiSelectorDialog
        data={countriesDialog}
        title="Parties"
        columns={3}
        size="md"
        savedChipsKey="_iclrc_bd_parties"
        />
    </Form>
  );
}

const InstumentDocuments = (props: { instrumentId: number }) => {
  return <AttachedDocumentsBound
    apiPath={InstrumentApiPath}
    parentId={props.instrumentId}
    documentLinkPrefix="/api/instrument/published/document"
    />
}