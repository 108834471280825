import React from 'react';
import { useParams } from 'react-router-dom';
import { useCaseRecordEdit } from './useCaseRecordEdit';
import { DeleteButton, Form, FormGrid, MoreActionsMenuButton, OccupyFreeSpace, SaveButton, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { Divider, IconButton, Typography } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { BiodevEditor } from '../Administration/BiodevEditor';
import { AttachedDocumentsBound } from '../Common/AttachedDocuments';
import { CaseRecordApiPath } from './useCaseRecordsList';
import { InboundLinksListOnCtx } from '../Common/Links/InboundLinksList';
import { InboundLinksContextProvider } from '../Administration/Editor/InboundLinksContext';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';
import { DocumentLinksPane } from '../Common/DocumentLinks';
import { FileUploadDialog } from '../../toolympus/components/files';
import { DocumentStatus } from '../Administration/Moderation';
import { PlaceholderField } from '../../toolympus/components/schemed';
import { ContentWithModerationWrapper, DocumentModerationPane } from '../Administration/Moderation/DocumentModerationPane';
import { PowerEditorExternalControlProviderBase } from '../../toolympus/components/PowerDoc/slate/PowerEditorControlContext';
import { createModerationCommentPlugin } from '../Administration/Moderation/ModerationCommentPlugin';

export const CaseRecordForm = () => {
  const { id } = useParams<{ id: string }>();
  const data = useCaseRecordEdit(+id);

  const { controls } = useItemWithControls(data, { schema: data.schema });

  useSaveable(data);

  const tabs = useTabsState([
    ["attributes","Attributes"],
    ["content","Content"],
    ["files","Files"],
  ], "attributes", "tab");

  const startCommentCommandPlugin = createModerationCommentPlugin(data.moderationFeed);


  return (
    <Form
      title="Case"
      headerItems={<>
        <OccupyFreeSpace />
        {data.hasChanges && <SaveButton action={() => data.save()} />}

        <MoreActionsMenuButton
          strippedButton
          actions={[
            ["content-upload", "Import PDF", () => data.contentFile.dialog.open(), { disabled: data.hasChanges }],
          ]}
          />

        <DeleteButton
          remove={() => data.remove()}
          title="Delete the case?"
          button={<IconButton size="small"><DeleteOutlined /></IconButton>}
          />
        <PluggableDocumentationButton documentationKey="cases" />
      </>}
      >
      <FormGrid columns="2fr 1fr 1fr" style={{ alignItems: "flex-end" }}>
        {controls([
          ["title"],
          ["case_date"],
        ])}

        <DocumentStatus
          document={data.data}
          schema={data.schema}
          updateStatus={data.updateDocumentModeration}
          />
      </FormGrid>

      <TabsHeader state={tabs} />

      <TabPanel state={tabs.forTab("attributes")}>
        <FormGrid>
          {controls([
            ["countries", { autoComplete: true, multiSelectValueDisplay: "text" }],
            ["decision_body", { autoComplete: true }],
            ["source_url"],
            ["owner_id", { autoComplete: true }],
            ["moderator_id", { autoComplete: true }],
            ["created_by", { disabled: true }],
            [PlaceholderField],
            [PlaceholderField],
            ["created_at", { disabled: true, utcToLocal: true }],
          ])}
        </FormGrid>

        <FormGrid columns="1fr">
          {controls([
            ["topics", { autoComplete: true, multiSelectValueDisplay: "text" }],
          ])}


          <Typography variant="caption" component="p" color="textSecondary" style={{ marginTop: "1rem" }}>Description / abstract</Typography>
          <BiodevEditor
            key={`${data.data._id}`}
            content={data.data.description}
            update={v => data.update({ description: v })}
            />

          <Divider />

          <FormGrid columns="1fr 1fr" forceEvenColumns>

            <FormGrid columns="1fr" noMargin>
              <Typography variant="caption" color="textSecondary">
                Associated documents
              </Typography>

              <DocumentLinksPane
                thisRecordType="case_record"
                thisRecordId={data.data._id}
                />
            </FormGrid>

            <InboundLinksContextProvider this_record_type="case_record" this_record_id={`${data.data._id}`}>
              <InboundLinksListOnCtx label="Links" />
            </InboundLinksContextProvider>
          </FormGrid>


        </FormGrid>
      </TabPanel>



      <TabPanel state={tabs.forTab("content")}>
        <PowerEditorExternalControlProviderBase>
          <ContentWithModerationWrapper isFeedVisible={data.moderationFeed.isFeedVisible || false}>
            
            <BiodevEditor
              key={`${data.data._id}_${data.loadBumper || ""}`}
              content={data.data.content}
              update={v => data.update({ content: v })}
              editorElementId="doc-editor"
              extraPlugins={[startCommentCommandPlugin]}
              />

            <DocumentModerationPane
              data={data.moderationFeed}
              isFeedVisible={data.moderationFeed.isFeedVisible || false}
              setIsFeedVisible={data.moderationFeed.setIsFeedVisible}
              loadBumper={(data.loadBumper || 0) + (data.moderationFeed.tasks.loadBumper || 0)}
              />

          </ContentWithModerationWrapper>
        </PowerEditorExternalControlProviderBase>
        
      </TabPanel>



      <TabPanel state={tabs.forTab("files")}>
        <CaseRecordDocuments caseRecordId={data.data._id} />
      </TabPanel>


      <FileUploadDialog
        data={data.contentFile}
        title="Import content">
        <Typography>
          Upload a PDF-file using the field below to import document content from it.
        </Typography>
        <Typography>
          The uploaded file will be added to the Files tab.
        </Typography>
        <Typography>
          Warning: if the document already has content, it will be overwritten.
        </Typography>
      </FileUploadDialog>
    </Form>
  );
}


const CaseRecordDocuments = (props: { caseRecordId: number }) => {
  return <AttachedDocumentsBound
    apiPath={CaseRecordApiPath}
    parentId={props.caseRecordId}
    documentLinkPrefix="/api/case-record/published/document"
    />
}